import React, { useRef } from 'react';
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react';

const sidebarContainer = css`
  position: relative;
  grid-area: aside;
  background-color: var(--silver);
`;

const leftSidebarStyle = css`
  position: sticky;
  left: 0;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 1rem;
  top: 0;
  @media (min-width: 769px) and (max-width: 1024px) {
    min-height: calc(100vh - 5.625rem);
  }
  min-height: calc(100vh - 7rem);
  max-height: 100vh;
  overflow: auto;
  background: var(--white);
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    display: none;
  }
`;
const progressContainerStyle = css`
  position: relative;
  width: 100%;
  background-color: var(--gray);
  border: 1px solid var(--gray);
  border-radius: 8px;
  > {
    color: var(--gray);
    font-size: 0.75rem;
    line-height: 1.33em;
  }
`;
const barContainerStyle = css`
  height: 1rem;
  background-color: var(--primaryBrand);
  border-radius: 8px;
  text-align: center;
  line-height: 1rem;
  color: var(--gray);
  transition: width 0.75s 0.1s;
`;

const mobileProgressStyle = css`
  display: none;
  @media (max-width: 768px) {
    grid-area: aside;
    display: block;
    margin: 0 1rem;
  }
`;

export const EmptySidebar = observer(() => {
  const ref = useRef(null);

  const progress = 0;
  const barWidthStyle = css`
    width: ${progress}%;
  `;
  return (
    <aside className={sidebarContainer} ref={ref}>
      <div className={leftSidebarStyle} />
      <div className={mobileProgressStyle}>
        <div className={progressContainerStyle}>
          <div className={cx(barContainerStyle, barWidthStyle)} />
        </div>
      </div>
    </aside>
  );
});
