import { makeAutoObservable } from 'mobx';

export class QuoteStore {
  salesforceLeadId = undefined;
  salesforceContactId = undefined;
  salesforceOpportunityId = undefined;

  constructor() {
    makeAutoObservable(this);
  }
  setValues({
    salesforceOpportunityId,
    salesforceContactId,
    salesforceLeadId,
  }) {
    this.salesforceOpportunityId = salesforceOpportunityId;
    this.salesforceContactId = salesforceContactId;
    this.salesforceLeadId = salesforceLeadId;
  }
}

export const quoteStore = new QuoteStore();
