export const GET_LOOKUPS = 'getLookups';
export const GET_ADDRESS_AUTOCOMPLETE = 'getAddressAutocomplete';
export const GET_ADDRESS_DETAILS = 'getAddressDetails';
export const RATE_QUOTE = 'rateQuote';
export const REPRICE_QUOTE = 'repriceQuote';
export const SAVE_QUOTE = 'saveQuote';
export const SAVE_MTA = 'saveMTA';
export const RATE_MTA = 'rateMTA';
export const CREATE_INITIAL_MTA = 'createInitialMTA';
export const SAVE_QUOTE_PERKBOX = 'saveQuotePerkbox';
export const SAVE_POLICY_MYDENTIST = 'savePolicyMyDentist';
export const CLAIM_QUOTE = 'claimQuote';
export const SAVE_POLICY = 'savePolicy';
export const GET_POLICY = 'getPolicy';
export const CLAIM_POLICY = 'claimPolicy';
export const CHECKPOLICYCLAIMSTATUS = 'checkPolicyClaimStatus';
export const CHECKQUOTECLAIMSTATUS = 'checkQuoteClaimStatus';
export const GET_USER_POLICIES = 'getUserPolicies';
export const GET_USER_POLICY = 'getUserPolicy';
export const UPDATE_USER_QUOTE = 'updateUserQuote';
export const GET_USER_QUOTE = 'getUserQuote';
export const GET_QUOTE = 'getQuote';
export const IS_INSYNC_CUSTOMER = 'isInsyncCustomer';
export const GET_USER_QUOTES = 'getUserQuotes';
export const TAKE_UP_MTA = 'takeUpMTA';
export const CREATE_MTA_PERKBOX = 'createMTAPerkbox';
export const CONFIRM_EMAIL = 'confirmEmail';
export const SEND_EMAIL_CONFIRMATION = 'sendEmailConfirmation';
export const GENERATE_POST_SIGNUP_MTA = 'generatePostSignupMTA';
export const CREATE_USER = 'createUserAndSendVerifyEmail';
export const PAYMENT_INTENT = 'paymentIntent';
export const MOVE_POLICY_TO_API = 'movePolicyToAPI';
export const DOWNLOAD_DOCUMENT = 'downloadDocument';
export const SEND_QUOTE_FOR_REVIEW = 'sendQuoteForReview';
export const CREATE_INITIAL_QUOTE = 'createInitialQuote';
export const CREATE_INITIAL_RENEWAL = 'createInitialRenewal';
export const RATE_RENEWAL = 'rateRenewal';
export const TAKE_UP_RENEWAL = 'takeUpRenewal';

export const GET_QUOTE_INFO_FROM_LEAD_ID = 'getQuoteInfoFromLeadID';
export const GET_QUOTE_INFO_FROM_OPPORTUNITY_ID =
  'getQuoteInfoFromOpportunityID';
export const CREATE_INITIAL_LEAD = 'createInitialLead';
//SCHEME SERVE
export const RATE_SCHEME_SERVE = 'rateSchemeServe';
// DVLA
export const GET_VEHICLE_INFO = 'getVehicleInfo';
